(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("styled-components"), require("@material-ui/core/styles"), require("@material-ui/core/Tooltip"), require("@material-ui/core/FormControl"), require("@material-ui/core/FormControlLabel"), require("@material-ui/core/MenuItem"), require("@material-ui/core/TableCell"), require("@material-ui/core/TableRow"), require("@material-ui/core/AccordionActions"), require("@material-ui/core/AccordionDetails"), require("@material-ui/core/Accordion"), require("@material-ui/core/AccordionSummary"), require("@material-ui/core/Table"), require("@material-ui/core/TableBody"), require("@material-ui/core/TableSortLabel"), require("@material-ui/core/TableContainer"), require("@material-ui/core/TableHead"), require("@material-ui/core/Paper"), require("@material-ui/core/Box"), require("@material-ui/core/Collapse"), require("@material-ui/core/Button"), require("@material-ui/core/Checkbox"), require("@material-ui/core/FormHelperText"), require("@material-ui/core/Radio"), require("@material-ui/core/RadioGroup"), require("@material-ui/core/Select"), require("@material-ui/core/Switch"), require("@material-ui/core/TextField"), require("@material-ui/core/CircularProgress"), require("@material-ui/core/Stepper"), require("@material-ui/core/Step"), require("@material-ui/core/StepLabel"), require("@material-ui/core/Tab"), require("@material-ui/core/Tabs"), require("@material-ui/core/Menu"), require("react-dom"), require("@material-ui/core/Modal"));
	else if(typeof define === 'function' && define.amd)
		define([, , "@material-ui/core/styles", "@material-ui/core/Tooltip", "@material-ui/core/FormControl", "@material-ui/core/FormControlLabel", "@material-ui/core/MenuItem", "@material-ui/core/TableCell", "@material-ui/core/TableRow", "@material-ui/core/AccordionActions", "@material-ui/core/AccordionDetails", "@material-ui/core/Accordion", "@material-ui/core/AccordionSummary", "@material-ui/core/Table", "@material-ui/core/TableBody", "@material-ui/core/TableSortLabel", "@material-ui/core/TableContainer", "@material-ui/core/TableHead", "@material-ui/core/Paper", "@material-ui/core/Box", "@material-ui/core/Collapse", "@material-ui/core/Button", "@material-ui/core/Checkbox", "@material-ui/core/FormHelperText", "@material-ui/core/Radio", "@material-ui/core/RadioGroup", "@material-ui/core/Select", "@material-ui/core/Switch", "@material-ui/core/TextField", "@material-ui/core/CircularProgress", "@material-ui/core/Stepper", "@material-ui/core/Step", "@material-ui/core/StepLabel", "@material-ui/core/Tab", "@material-ui/core/Tabs", "@material-ui/core/Menu", , "@material-ui/core/Modal"], factory);
	else if(typeof exports === 'object')
		exports["\"@gnosis.pm/safe-react-components\""] = factory(require("react"), require("styled-components"), require("@material-ui/core/styles"), require("@material-ui/core/Tooltip"), require("@material-ui/core/FormControl"), require("@material-ui/core/FormControlLabel"), require("@material-ui/core/MenuItem"), require("@material-ui/core/TableCell"), require("@material-ui/core/TableRow"), require("@material-ui/core/AccordionActions"), require("@material-ui/core/AccordionDetails"), require("@material-ui/core/Accordion"), require("@material-ui/core/AccordionSummary"), require("@material-ui/core/Table"), require("@material-ui/core/TableBody"), require("@material-ui/core/TableSortLabel"), require("@material-ui/core/TableContainer"), require("@material-ui/core/TableHead"), require("@material-ui/core/Paper"), require("@material-ui/core/Box"), require("@material-ui/core/Collapse"), require("@material-ui/core/Button"), require("@material-ui/core/Checkbox"), require("@material-ui/core/FormHelperText"), require("@material-ui/core/Radio"), require("@material-ui/core/RadioGroup"), require("@material-ui/core/Select"), require("@material-ui/core/Switch"), require("@material-ui/core/TextField"), require("@material-ui/core/CircularProgress"), require("@material-ui/core/Stepper"), require("@material-ui/core/Step"), require("@material-ui/core/StepLabel"), require("@material-ui/core/Tab"), require("@material-ui/core/Tabs"), require("@material-ui/core/Menu"), require("react-dom"), require("@material-ui/core/Modal"));
	else
		root["\"@gnosis.pm/safe-react-components\""] = factory(root[undefined], root[undefined], root["@material-ui/core/styles"], root["@material-ui/core/Tooltip"], root["@material-ui/core/FormControl"], root["@material-ui/core/FormControlLabel"], root["@material-ui/core/MenuItem"], root["@material-ui/core/TableCell"], root["@material-ui/core/TableRow"], root["@material-ui/core/AccordionActions"], root["@material-ui/core/AccordionDetails"], root["@material-ui/core/Accordion"], root["@material-ui/core/AccordionSummary"], root["@material-ui/core/Table"], root["@material-ui/core/TableBody"], root["@material-ui/core/TableSortLabel"], root["@material-ui/core/TableContainer"], root["@material-ui/core/TableHead"], root["@material-ui/core/Paper"], root["@material-ui/core/Box"], root["@material-ui/core/Collapse"], root["@material-ui/core/Button"], root["@material-ui/core/Checkbox"], root["@material-ui/core/FormHelperText"], root["@material-ui/core/Radio"], root["@material-ui/core/RadioGroup"], root["@material-ui/core/Select"], root["@material-ui/core/Switch"], root["@material-ui/core/TextField"], root["@material-ui/core/CircularProgress"], root["@material-ui/core/Stepper"], root["@material-ui/core/Step"], root["@material-ui/core/StepLabel"], root["@material-ui/core/Tab"], root["@material-ui/core/Tabs"], root["@material-ui/core/Menu"], root[undefined], root["@material-ui/core/Modal"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__2__, __WEBPACK_EXTERNAL_MODULE__8__, __WEBPACK_EXTERNAL_MODULE__9__, __WEBPACK_EXTERNAL_MODULE__10__, __WEBPACK_EXTERNAL_MODULE__11__, __WEBPACK_EXTERNAL_MODULE__13__, __WEBPACK_EXTERNAL_MODULE__14__, __WEBPACK_EXTERNAL_MODULE__15__, __WEBPACK_EXTERNAL_MODULE__16__, __WEBPACK_EXTERNAL_MODULE__17__, __WEBPACK_EXTERNAL_MODULE__18__, __WEBPACK_EXTERNAL_MODULE__19__, __WEBPACK_EXTERNAL_MODULE__20__, __WEBPACK_EXTERNAL_MODULE__21__, __WEBPACK_EXTERNAL_MODULE__22__, __WEBPACK_EXTERNAL_MODULE__23__, __WEBPACK_EXTERNAL_MODULE__24__, __WEBPACK_EXTERNAL_MODULE__25__, __WEBPACK_EXTERNAL_MODULE__26__, __WEBPACK_EXTERNAL_MODULE__27__, __WEBPACK_EXTERNAL_MODULE__28__, __WEBPACK_EXTERNAL_MODULE__29__, __WEBPACK_EXTERNAL_MODULE__30__, __WEBPACK_EXTERNAL_MODULE__31__, __WEBPACK_EXTERNAL_MODULE__32__, __WEBPACK_EXTERNAL_MODULE__33__, __WEBPACK_EXTERNAL_MODULE__34__, __WEBPACK_EXTERNAL_MODULE__40__, __WEBPACK_EXTERNAL_MODULE__41__, __WEBPACK_EXTERNAL_MODULE__42__, __WEBPACK_EXTERNAL_MODULE__43__, __WEBPACK_EXTERNAL_MODULE__44__, __WEBPACK_EXTERNAL_MODULE__45__, __WEBPACK_EXTERNAL_MODULE__46__, __WEBPACK_EXTERNAL_MODULE__47__, __WEBPACK_EXTERNAL_MODULE__49__) {
return 